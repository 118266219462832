.dropzone {
  max-width: 450px;
}
.filedrag {
  font-weight: bold;
  text-align: center;
  padding: 1em 1em;
  margin: 1em 0;
  color: #555;
  border: 2px dashed #555;
  border-radius: 7px;
  cursor: default;
}

.filedrag:hover {
  color: rgb(95, 95, 95);
  background-color: rgba(230, 230, 230, 0.418);
  border-color: #555;
  box-shadow: inset 0 3px 4px #888;
}
