.container {
  margin: 10px;
}

.d-none {
  display: none;
}

.screenshotone-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  .btn {
    flex-shrink: 0;
    width: 250px;
    height: 40px;
    padding: 5px 20px;
    margin-bottom: 10px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: auto;
      margin-bottom: 10px;
    }
  }

  .alternate-link {
    /* FIXME: hidden for now */
    display: none;

    opacity: 1;
    transition: 0.2s;
    cursor: pointer;
    filter: grayscale(0);

    &:hover {
      opacity: 0.5;
    }

    &.is-disabled {
      filter: grayscale(1);
      opacity: .4;
      pointer-events: none;
    }
  }

  .name-box {
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
}

.screenshotone-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name-box {
  width: 300px;
  padding: 10px;
  margin: 10px;
}

.btn-screenshot {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
}

@media screen and (max-width: 820px) {
  .screenshotone-wrapper {
    padding-top: 20px;

    .btn {
      margin-bottom: 10px;
      width: calc(100% - 20px);
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 360px) {
  .screenshotone-wrapper {
    padding-top: 20px;

    .btn {
      margin-bottom: 10px;
      width: 100%;
      margin-right: 0;
    }

    input {
      width: 100%;
    }
  }

  .screenshotone-form {
    width: 100%;
  }
}
