.btn-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 270px;
  /* FIXME: too rigidly static */
  width: 550px; 
  margin: 15px;
}

.btn-group .btn {
  height: 40px;
}

.divider {
  opacity: 0;
  pointer-events: none;
  height: 40px;
}

@media screen and (max-width: 575px) {
  .btn-group {
    height: auto;
  }

  .btn-group .btn {
    height: auto;
  }

  .download-wrapper {
    flex-direction: column;

    div,
    button {
      width: 100%;
    }
  }

  .divider {
    display: none;
  }
}

.btn-group-label {
  display: block;
  font-weight: 500;
}

.btn {
  background-color: rgb(0, 139, 253);
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: block;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0 4px 6px -6px rgb(102, 102, 102);
  cursor: pointer;

  .ld-spin {
    margin-left: 8px;
  }

  &.is-loading,
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.is-disabled {
    background: rgb(194, 194, 194);
  }
}

.btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-download {
  position: relative;
  width: 150px;
  height: 40px;
  background-color: green;
}

.btn:disabled {
  background-color: rgb(194, 194, 194);
  cursor: default;
}

.btn:not(:disabled):hover {
  text-decoration: underline;
}

.btn.is-active:hover {
  text-decoration: none;
}

@media screen and (min-width: 5120px) {
  .btn {
    font-size: 17px;
  }
}

.btn-primary {
  background-color: rgb(0, 139, 253);
  color: white;
}

.btn-selected {
  -moz-box-shadow: inset 0 0 5px #00000075;
  -webkit-box-shadow: inset 0 0 5px #03030375;
  box-shadow: inset 0 0 5px green;
  background-color: rgb(0, 122, 221);
}

.btn-selected.is-active {
  background-color: rgb(0 82 148);
}