.crop-container {
  width: 100%;
  height: 550px;
  min-height: auto;
  position: relative;
}

@media screen and (min-width: 5120px) {
  .crop-container {
    width: 100%;
    height: 1350px;
    position: relative;
  }
}

.controls {
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
}

.slider {
  padding: 30px 0px;
}

.button {
  text-align: center;
}

.centered {
  display: flex;
  justify-content: center;
}

.aspect-ratio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
