.lds-dual-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  width: 26px;
  height: 26px;
  transform: translate(-50%, -50%);
}
.lds-dual-ring.is-invisible {
  visibility: hidden;
}
.lds-dual-ring.is-active {
  display: inline-block;
}
.lds-dual-ring::after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  margin: 2px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
